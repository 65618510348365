import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { FaRegTimesCircle, FaTimesCircle,FaLink, FaTools } from 'react-icons/fa';
import { ReactComponent as PowerBiIcon } from "../assets/img/icons8-power-bi-2021.svg";

export default function DetailsPage(props) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function generateClassName(category) {
    const words = category.split(" ");
    let className = "" + words[0].toUpperCase(); // First letter of the first word

    if (words.length > 1) {
        const secondWord = words[1];
        if (secondWord && secondWord.length > 0) {
            className += "-" + secondWord.toUpperCase();
        }
    }

    return className.toLowerCase();
}

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button style={{background:"#3d3d3d",width:"auto",padding:"10px",fontSize:"12px"}} className='submit_btn' onClick={toggleDrawer(anchor, true)}>Overview</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            id='detailsPanel'
          >
            <div style={{display:"flex",justifyContent:"space-between",padding:"15px",alignContent:"center",alignItems:"center",position:"sticky",top:0,backgroundColor:"white"}}>
                <div style={{fontSize:"24px",fontWeight:"600"}}>Details Overview</div>
                <div onClick={toggleDrawer(anchor, false)}><FaRegTimesCircle style={{fontSize:"24px",cursor:"pointer"}}/></div>
            </div>
            <Divider/>

            <div className='detailsSection'>
                <div className='details-list'>
                    <div className='headerDetails'> <FaLink style={{fontSize:"14px"}}/> Work Item </div>
                    <div>{props.name}</div>
                </div>

                <div className='details-list'>
                    <div className='headerDetails'> <FaLink style={{fontSize:"14px"}}/> Description </div>
                    <div>{props.desc || "N/A"}</div>
                </div>

                <div className='details-list'>
                    <div className='headerDetails'> <FaLink style={{fontSize:"14px"}}/> Catagory </div>
                    <div>
                    {props.type.length !== 0 && (
                          <div
                          style={{
                            display: "grid",
                            textAlign: "center",
                            wordBreak: "break-word",
                          }}
                          className='TagCls'
                        >
                          <div className='List_items'>
                          {props.type.map((item,index) =>{
                            return(
                              <div key={index} className={`item-type item-type-${generateClassName(item)}`}>
                                <span title={item} className='item-type-span'>
                                  {item}
                                </span>
                              </div>
                            )
                          })}
                          </div>
                        </div>
                        )}
                    </div>
                </div>

                <div className='details-list'>
                    <div className='headerDetails'><FaLink style={{fontSize:"12px"}}/> Dashboard Type </div>
                    <div style={{display:"grid",justifyContent:"flex-start"}}>

                        {props.reportType === "Power BI" ? (
                            <div className="powerBitag">
                              <PowerBiIcon width={10} height={10} />
                              PowerBI
                            </div>
                          ) : (
                            <>
                              <div className="ApplicationTag">
                                <FaTools style={{ fontSize: "10px" }} />
                                Applications
                              </div>
                            </>
                          )}
                      </div>
                </div>

                <div className='details-list'>
                    <div className='headerDetails'><FaLink style={{fontSize:"12px"}}/> Pogress </div>
                    <div className='item-completed'>Completed</div>
                </div>

                <div className='details-list'>
                    <div className='headerDetails'> <FaLink style={{fontSize:"12px"}}/> LInk To The Dashboard </div>
                    <div>
                        {props.Link !== null ? (
                        <a href={`${props.Link}`}>Click</a>

                        ) : (
                          <>N/A</>
                        )}
                    </div>
                </div>

                

            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
