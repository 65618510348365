import * as React from "react";
import Card from "@mui/material/Card";

import { FaDatabase, FaRegChartBar, FaTools, FaUserCog } from "react-icons/fa";

export default function CustomCards(props) {
  // console.log(props.count)
  const isdown =
    props.count !== null ? (props.count > 0 ? true : false) : undefined;

  //  console.log("isdown")
  //  console.log(isdown)

  return (
    <Card className="card_box_new_div" sx={{ maxWidth: 345 }}>
      <div style={{ display: "grid", gap: "10px", width: "100%" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            padding: "1rem",
            alignContent: "baseline",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "grid", gap: "20px", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "left",
                }}
              >
                {props.title}
              </div>

              <div class="annualCss">
                <div class="annualItmes">{props.type}</div>
              </div>
            </div>

            <span
              style={{
                fontWeight: "600",
                fontSize: "22px",
                color: "3d3d3d",
                textAlign: "left",
              }}
              className={`${props.className}`}
            >
              {props.number}
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "left",fontSize:"14px" }}>23rd, April, 2024 12AM</div>
              <div style={{ paddingRight: "10px" }}>
                {props.type === "PowerBI" ? (
                  <FaRegChartBar style={{ fontSize: "24px" }} />
                ) : props.type === "SAP" ? (
                  <FaDatabase style={{ fontSize: "20px" }} />
                ) : props.type === "Active" ? (
                  <FaUserCog style={{ fontSize: "20px" }} />
                ) : props.type === "Tools" ? (
                  <FaTools style={{ fontSize: "20px" }} />
                ) : (
                  <FaRegChartBar style={{ fontSize: "20px" }} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
