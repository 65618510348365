import React from 'react';

function InputField({ label, name }) {
  return (
    <div style={{ display: 'flex', gap: '12px', padding: '1rem' }}>
      <div style={{ fontWeight: '600' }}>{label}:</div>
      <input type='text' name={name} className='input_field' placeholder={label}/>
    </div>
  );
}

function EditDataDirectory() {
  return (
    <div className='main-container'>
      <div className='heading'>Edit Data Directory</div>

        <div style={{}}>
            <InputField label="KPI Name" name="kpi_name" />
            <InputField label="Business definition" name="business"/>
            <InputField label="Function" name="function" />
            <InputField label="Business Process" name="business_process" />
            <InputField label="If Yes, Formula" name="If_yes" />
            <InputField label="Synonyms" name="synonyms" />
            <InputField label="Gold KPIs" name="gold_kpi" />
        </div>

      <div style={{display: 'flex', justifyContent: 'center'}}>
      <button type="button" class="btn btn-primary" >Submit</button>
        </div>
    </div>
  );
}

export default EditDataDirectory;
