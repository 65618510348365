import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import classes from "./UserInfo.module.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import UserForm from "./UserForm";
import UserTable from "./UserTable";

const UserInfo = ({
  users,
  onAddData,
  onDeleteData,
  setPrintData,
  printData,
}) => {
  const [filteredArray, setFilteredArray] = useState(users);
  const [editableUser, setEditableUser] = useState({});
  const [typeFilter, setTypeFilter] = useState(false);
  // const [groupFilter, setGroupFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userDeleteID, setUserDeleteID] = useState(null);
  const [clearData, setClearData] = useState(true);
  const printRef = useRef();

  useEffect(() => {
    setFilteredArray(users);
  }, [users]);

  const changeHandler = (e) => {
    const { value } = e.target;
    // if (value) {
    //   setFilteredArray((prevState) => {
    //     return prevState.filter((ele) => {
    //       // Check if the value is "Admin" or "Member"
    //       return ele.Role.toLowerCase().includes(value.toLowerCase());
    //       // if (value === "Admin" || value === "Member") {
    //       //   // Check if the role (converted to lowercase) includes the value (converted to lowercase)
    //       //   setTypeFilter(true);
    //       // }
    //       // else {
    //       //   // Check if the UserGroup includes the value
    //       //   setGroupFilter(true);
    //       //   return ele.UserGroup.includes(value);
    //       // }
    //     });
    //   });
    // }

    setFilteredArray((prevState) => {
      return users.filter((ele) => {
        // Check if the value is "Admin" or "Member"
        setTypeFilter(true);
        return ele.Role.toLowerCase().includes(value.toLowerCase());
        // if (value === "Admin" || value === "Member") {
        //   // Check if the role (converted to lowercase) includes the value (converted to lowercase)
        // }
        //  else {
        //   // Check if the UserGroup includes the value
        //   setGroupFilter(true);
        //   return ele.UserGroup.includes(value);
        // }
      });
    });
  };

  const filterRefresh = () => {
    setFilteredArray(users);
    setTypeFilter(false);
    // setGroupFilter(false);
  };

  // Function to handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterUsers(query);
  };

  // Function to filter users based on search query
  const filterUsers = (query) => {
    const filtered = users.filter((user) => {
      return (
        user.Email.toLowerCase().includes(query.toLowerCase()) ||
        user.AssignedBy.toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredArray(filtered);
  };

  const editHandler = (userId) => {
    const filteredUser = filteredArray.filter((user) => user.ID === userId);
    setEditableUser((prevState) => {
      return { ...filteredUser[0] };
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (printData) {
      handlePrint();
      setPrintData(false);
    }
  }, [printData, setPrintData]);

  const editFlag = Object.keys(editableUser).length === 0;

  return (
    <div className={classes["user-info-container"]}>
      <div className={classes["find-section"]}>
        {/* Text input for searching */}
        <div className={classes["search-bar-container"]}>
          <div className={classes["search-bar"]}>
            <SearchIcon className={classes["search-lens"]} />
            <input
              type="text"
              placeholder="Search for Email or Assigned By..."
              value={searchQuery}
              onChange={handleSearchChange}
              // Add any necessary event handlers or state management here
            />
            {searchQuery.length > 0 && (
              <ClearIcon
                className={classes["cross-icon"]}
                onClick={() => {
                  setSearchQuery("");
                  setFilteredArray(users);
                }}
              />
            )}
          </div>
          <button
                            style={{background:"#3d3d3d"}}

            className={classes["add-user-btn"]}
            onClick={() => {
              setClearData(true);
              setOpenModal(true);
            }}
          >
            + Add New User
          </button>
        </div>

        {/* Select inputs */}
        <div className={classes["filter-section"]}>
          {/* <div className={classes["filter-text"]}>Filter by</div> */}
          <select className={classes["filter-input"]} onChange={changeHandler}>
            <option value="" disabled selected={!typeFilter} hidden>
              Select User Type
            </option>
            <option value="Admin">Admin</option>
            <option value="Member">Member</option>
          </select>

          {/* <select className={classes["filter-input"]} onChange={changeHandler}>
            <option value="" selected={!groupFilter} disabled hidden>
              User Group
            </option>
            <option value="Group A">Group A</option>
            <option value="Group B">Group B</option>
            <option value="Group C">Group C</option>
            <option value="Group D">Group D</option>
          </select> */}

          <button
            style={{
              fontSize: "14px",
              padding: "12px",
              border: "none",
              width: "50px",
              borderRadius: "6px",
            }}
            onClick={filterRefresh}
          >
            <RefreshIcon />
          </button>
        </div>
      </div>

      <div className={classes["pagination-container"]}>
        <p className={classes["length-text"]}>
          Total number of users: <span>564</span>
        </p>
      </div>
      <UserTable
        filteredArray={filteredArray}
        editHandler={editHandler}
        setDeleteModal={setDeleteModal}
        setUserDeleteID={setUserDeleteID}
        setClearData={setClearData}
        setOpenModal={setOpenModal}
        ref={printRef}
      />

      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        id="user-form-modal"
      >
        <DialogTitle id="modal-dialog-title">
          {editFlag || clearData ? "Add New User" : "Edit User Information"}
        </DialogTitle>
        <DialogContent className={classes["modal-content"]}>
          <UserForm
            closeHandler={() => {
              setOpenModal(false);
            }}
            addUser={onAddData}
            selectedUser={editableUser}
            editFlag={editFlag}
            clearData={clearData}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        id="delete-modal"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure, you want to Delete this record ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm your decision.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "16px" }}>
          <button
            className={`${classes["secondary-btn"]} ${classes.btn}`}
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            No
          </button>
          <button
            className={`${classes["primary-btn"]} ${classes.btn}`}
            onClick={() => {
              setDeleteModal(false);
              onDeleteData(userDeleteID);
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserInfo;
