import React, { useState } from "react";
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import { styled } from "@mui/material/styles";
import { ReportData } from "./ReportData";
// import { FaLightbulb, FaMagic, FaRegChartBar } from "react-icons/fa";
import datajson from '../../HomeData';
import Cards from "./Cards";
import CustomCards from "../reuseable/CustomCards";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const ReoprtHeading = ['Objective of report', 'Business benefits', 'General Description of report (used in forums, data feeds into other reports, escalations based on the report?)',
'Optimisation opportunities (potential) in the report', 'Report Complexity Analysis (No. of measures, calculations, visualizations, ease of navigation etc)',
'Gaps shared by Business', 'Performance - User experience stand-point', 'Performance - User experience stand-point']

const Home = () => {
  const[data, setData] = useState(datajson);
  const[openModal, setOpenModal] = useState(false);
  const[reportName, setReportName] = useState('');

console.log(reportName, 'reportName');
  const reportDetails = ReportData.filter((obj) => (obj.name.toLowerCase()) === (reportName.toLowerCase()));

  // const color = ['#0000a0', '#1a0127', '#14b965', '#9baf83', '#32a939', '#253978', '#57f22c', '#87115d', '#db05e9', '#144e08', '#21ccaf', '#7281f2', '#d567d4', '#24d1b0', '#fa1411', '#ad002e', '#379fba', '#af90e1', '#4b503f', '#ab4495', '#24695f', '#ee1537', '#edcac5', '#dcc082', '#b11724', '#62436d', '#c4d461', '#055fbf', '#272981', '#d599ed', '#6e1784', '#3c52d4', '#dd4d94', '#c85623', '#bfb87a', '#62fb47', '#5af5e8', '#b82994', '#8e4d70', '#9ed736', '#c9f824', '#83e44f', '#c43716', '#4dad6b', '#d05427', '#d15853', '#dbe218', '#b7f0e0', '#7c56a3', '#fae8d1', '#58107f', '#81a065', '#7d1268', '#a13c68', '#c92613', '#bb7b82', '#e2cd4c', '#6f1e39', '#8440f4', '#09204a', '#e6f92d', '#989a3a', '#e92998', '#efaf3a']
  
  const handleSearch = (e) => {
    const { value } = e.target;
    const filteredData = datajson.filter((ele) => 
    ele.Header.toLowerCase().includes(value.toLowerCase()) || 
    ele.SubHeader.toLowerCase().includes(value.toLowerCase())
);    setData(filteredData);
}

  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />
          <div id="mainHome">
          <div style={{display: 'flex', justifyContent: 'space-between', padding:"2rem 1rem 1rem"}}>
            <div>
              <div style={{fontSize:"24px"}} className="heading">Digital Finance Suite</div>
            </div>
              {/* <button className="submit_btn" style={{fontWeight: '600', borderRadius: '4px'}}> + Add New Report</button> */}
          </div>
            <div className="dashBoardTab">
              <CustomCards type="Apps" title="Number of applications" number ={28}/>
              <CustomCards  type="Active" title="Onboarded users" number ={564}/>
              <CustomCards  type="SAP" title="Volume of data analyzed" number ={"50.23 GB"}/>
              <CustomCards  type="Tools" title="Enterprise App connected" number ={15}/>

            </div>

          <div style={{display: 'flex', justifyContent: 'space-between', padding:"3rem 1rem 1rem"}}>
            <div>
              <div style={{fontSize:"24px"}} className="heading">List of Solutions delivered</div>
              {/* <div className='line3' /> */}
            </div>
              {/* <button className="submit_btn" style={{fontWeight: '600', borderRadius: '4px'}}> + Add New Report</button> */}
          </div>



          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding:"1rem"}}>
            <div>
                <ul style={{
                marginBottom: "0px"
              }} class="nav nav-pills " id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                  <button class="nav-link itemName active" id="pills-all-tab" data-bs-toggle="pill" data-bs-target="#pills-all" type="button" role="tab" aria-controls="pills-all" aria-selected="true">All</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link itemName" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="false">Finance & Contolling</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link itemName" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">FP&A</button>
                  </li>
                  <li class="nav-item" role="presentation">
                  <button class="nav-link itemName" id="pills-demand-tab" data-bs-toggle="pill" data-bs-target="#pills-demand" type="button" role="tab" aria-controls="pills-demand" aria-selected="false">Procurement & Supply Chain</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link itemName" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Process & Compliance</button>
                </li>
              </ul>
            </div>

            <div className="search-box">
              <input type="text" className="search" placeholder="Search Applications" onChange={(e) => handleSearch(e)}/>
            </div>
          </div>



          <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab">
              <Cards data={data} setOpenModal={setOpenModal} setReportName={setReportName} category="All"/>
            </div>
            <div class="tab-pane fade show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <Cards data={data} setOpenModal={setOpenModal} setReportName={setReportName} category="Finance"/>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <Cards data={data} setOpenModal={setOpenModal} setReportName={setReportName} category="FP&A"/>
              </div>
            <div class="tab-pane fade" id="pills-demand" role="tabpanel" aria-labelledby="pills-demand-tab">
              <Cards data={data} setOpenModal={setOpenModal} setReportName={setReportName} category="supply_chain"/>
              </div>
              
            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <Cards data={data} setOpenModal={setOpenModal} setReportName={setReportName} category="Process_Compliance"/>
            </div>
          </div>
          
          </div>
        </Box>
      </Box>

    
    </div>
  );
};

export default Home;
