import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Button from "@mui/material/Button";
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import { FaDownload } from "react-icons/fa";

const ReportGenerate = ({ excelData, fileName, transformData = () => {} }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";
  var wscols = [
    { wch: 50 },
    { wch: 40 },
    { wch: 20 },
    { wch: 30 },
    { wch: 30 },
    { wch: 25 },
    { wch: 60 },
    { wch: 60 },
  ];
  const addStyles = (workSheet) => {

    Object.entries(workSheet)
    .filter(([k, v]) => k.charAt(0) === "A")
    .forEach(([k, v]) => {
      if (v.v === "Year") {
        v.s = {
          fill: {
            fgColor: { rgb: "FFFFFF" },
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
          font: {
            bold: false,
          }
        };
      } else {
        v.s = {
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText:true
          },
          width: {
            auto: true,
          },
          height:{
            auto:true
          } ,
          font: {
            bold: true,
          }      
        };
      }
    });

    Object.entries(workSheet)
    .filter(([k, v]) => ['B', 'C', 'D', 'E', 'F', 'G', 'H'].includes(k.charAt(0)))
    .forEach(([k, v]) => {
      v.s = {
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText:true
        },
        width: {
          auto: true,
        },
        height:{
          auto:true
        }
      };
    });

  };

  const excelDataLength = excelData.length;
  const rowHeight = Array(excelDataLength)
    .fill()
    .map((item) => ({ hpt: 30 }));

  const exportTOExcel = async () => {
  const transformedData = transformData(excelData);
    const ws = XLSX.utils.json_to_sheet(transformedData); 
    const range = ws["!ref"];
    ws["!autofilter"] = { ref: range };
    addStyles(ws);
    ws["!cols"] = wscols;
    ws["!rows"] = rowHeight;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const exelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([exelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      <button    
        style={{fontSize: "14px", padding: "12px",border:"none",width:"50px",borderRadius:"6px"}}
        onClick={(e) => exportTOExcel(fileName)}
        className="export-to-excel"
      >
        <FaDownload/>
      </button>
    </div>
  );
};

export default ReportGenerate;