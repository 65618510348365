export const dataDictionary =
[
    {
        "S. No.": 1,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Allocation Cases",
        "Business_definition": "# of cases that can be ordered for the item/customer combo",
        "Business_Process": "S&OE",
        "Report_Linked": "Daily Allocation & Quota Report\nATLAS AM: LAP SCM Production",
        "If_Yes_Formula": "∑Maximum Capacity",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "Quota",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "-Daily for reporting\n-Allocation is setup at the period level",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 2,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Confirmed Volume",
        "Business_definition": "# of cases/GSV confirming on a sales order that has not goods issued yet",
        "Business_Process": "",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Confirmed Cases\n∑Confirmed GSV",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 3,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Confirmed Weight",
        "Business_definition": "Pounds (lbs.) confirming for a given material in a sales document",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Confirming cases pounds (lbs.)",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Pounds",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level/Ship to",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 4,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Consumed Allocation",
        "Business_definition": "# of cases confirming or shipped on orders for the item/customer combo",
        "Business_Process": "S&OE",
        "Report_Linked": "Daily Allocation & Quota Report\nATLAS AM: LAP SCM Production",
        "If_Yes_Formula": "∑Quota Consumed Qty",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "Quota Consumed",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "-Daily for reporting\n-Allocation is setup at the period level",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 5,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Consumed Allocation %",
        "Business_definition": "% of allocation that has is confirming or shipped on orders to the item/customer combo",
        "Business_Process": "S&OE",
        "Report_Linked": "Daily Allocation & Quota Report\nATLAS AM: LAP SCM Production",
        "If_Yes_Formula": "∑Quota Consumed Qty / '∑Maximum Capacity",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "Quota Consumed %",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "-Daily for reporting\n-Allocation is setup at the period level",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 6,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Cut volume",
        "Business_definition": "# of cases not shipped to customer from a given order",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Ordered Cases - ∑Shipped Cases\n∑Ordered GSV - ∑Shipped GSV",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 7,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Fill rate",
        "Business_definition": "Fill rate or order fulfillment rate, is the rate of orders that can be shipped from available inventory without missing out on purchases due to stockouts, backorders, lost sales, etc",
        "Business_Process": "Measure past performance",
        "Report_Linked": "SPM V2 PROD (Use This)",
        "If_Yes_Formula": "(∑Shipped Cases / ∑Ordered Cases) * 100",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases (MD)",
        "Synonyms": "CSL (Case Fill Rate)",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 8,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Fill rate on time",
        "Business_definition": "Fill rate or order fulfillment rate on time, is the % of orders that can be shipped from available inventory without missing out on purchases due to stockouts, backorders, lost sales, etc AND are delivered to the customer at the time agreed",
        "Business_Process": "Measure past performance",
        "Report_Linked": "SPM V2 PROD (Use This)",
        "If_Yes_Formula": "Fill rate minus late fills",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 9,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Layer Ordering Compliance",
        "Business_definition": "Measure of what fraction of total shipments are orderd in full layers ( 1 layer= 21cases)",
        "Business_Process": "Customer Compliance Management",
        "Report_Linked": "2023 LCC feeder template",
        "If_Yes_Formula": "Line items not ordered in layers/Total line items ordered from a DC",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 10,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Order cycle time",
        "Business_definition": "From the time orders are placed till the time it is shipped out from warehouse",
        "Business_Process": "Logistics Cost Management",
        "Report_Linked": "TBD",
        "If_Yes_Formula": "∑(Order received time- Delivered time)",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Time (Days)",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "N/A",
        "Lowest_Desired_Granularity_Product_Level": "Item",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "MD",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 11,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Ordered Volume",
        "Business_definition": "# of cases ordered for a given material in a sales document",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Ordered Cases\n∑Ordered GSV\n",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "SAP data owner/FEs (to be identified)",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 12,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Ordered Weight",
        "Business_definition": "Pounds (lbs.) ordered for a given material in a sales document",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Ordered cases pounds (lbs.)",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Pounds",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level/Ship to",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 13,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "OTIF - On Time in Full",
        "Business_definition": "Measure of supplier’s ability to fulfill its delivery promises, meaning a customer receives exactly what was ordered, in the amount requested, at the correct location, and within the agreed upon timeframe.",
        "Business_Process": "Measure past performance",
        "Report_Linked": "SPM V2 PROD (Use This)",
        "If_Yes_Formula": "(∑Orders Supplied on Time in full / ∑Orders received) *100 ->How is on-time being measured?",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "RAD = recommended appointment date",
        "Lowest_Desired_Granularity_Geography": "DC/Plant",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 14,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Potential Cuts",
        "Business_definition": "# of cases/GSV not confirming on a sales order that has not goods issued yet",
        "Business_Process": "",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Ordered Cases - Confirmed Cases\n∑Ordered GSV - Confirmed GSV",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 15,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Remaining Allocation",
        "Business_definition": "# of cases remaining that can be ordered for the item/customer combo",
        "Business_Process": "S&OE",
        "Report_Linked": "Daily Allocation & Quota Report\nATLAS AM: LAP SCM Production",
        "If_Yes_Formula": "∑Maximum Capacity - ∑Quota Consumed Qty",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "Quota Remaining",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "-Daily for reporting\n-Allocation is setup at the period level",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 16,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Shipped volume",
        "Business_definition": "# of cases shipped for a given material in an invoice",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Shipped Cases",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 17,
        "Function_Business_lens": "Customer Service and Order Fulfillment",
        "KPI_Name": "Shipped Weight",
        "Business_definition": "Pounds (lbs.) shipped for a given material in a sales document",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Confirming cases pounds (lbs.)",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Pounds",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level/Ship to",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 18,
        "Function_Business_lens": "Logistics (Transportation)",
        "KPI_Name": "FSC (Fuel Surcharge)",
        "Business_definition": "An extra fee, determined as a percentage of the base rate, charged by transport companies to allow for the fluctuating costs of fuel. (per tonne)",
        "Business_Process": "Logistics Cost Management",
        "Report_Linked": "2023 LCC feeder template",
        "If_Yes_Formula": "Total fuel surcharge incurred for shipments/ Total tonnage of shipments",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 19,
        "Function_Business_lens": "Logistics (Transportation)",
        "KPI_Name": "FTC (Freight to Customer)",
        "Business_definition": "Per tonne cost to move finished goods from warehouse to customer",
        "Business_Process": "Report Performance",
        "Report_Linked": "2023 LCC feeder template",
        "If_Yes_Formula": "Linehaul + FSC / Tonnage shipped to customer",
        "Aggregation_Rules": "Invoice",
        "Unit_of_Aggregation": "Cost",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 20,
        "Function_Business_lens": "Logistics (Transportation)",
        "KPI_Name": "FTW (Freight to Warehouse)",
        "Business_definition": "Per tonne cost to move finished goods between warehouses",
        "Business_Process": "Report Performance",
        "Report_Linked": "2023 LCC feeder template",
        "If_Yes_Formula": "Linehaul + FSC / Tonnage shipped to warehouses",
        "Aggregation_Rules": "Invoice",
        "Unit_of_Aggregation": "Cost",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 21,
        "Function_Business_lens": "Logistics (Transportation)",
        "KPI_Name": "Linehaul",
        "Business_definition": "The expenses that occurred on haulage or transportation of freight from pickup point to the destination. (per tonne)",
        "Business_Process": "Logistics Cost Management",
        "Report_Linked": "2023 LCC feeder template",
        "If_Yes_Formula": "Total transportation cost of shipments/ Total tonnage of shipments",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 22,
        "Function_Business_lens": "Logistics (Transportation)",
        "KPI_Name": "Truck Utilization",
        "Business_definition": "Description of how much of the vehicle capacity is utilized. (overall capacity over a period)",
        "Business_Process": "There are 2xPower BI tools called \"US Logistics LOE\" and \"Logistics Operational Efficiency report EU\"",
        "Report_Linked": "2023 LCC feeder template",
        "If_Yes_Formula": "∑Order Shipped/(∑Total Truck Capacity)",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Metric Tonnes",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Periodically",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 23,
        "Function_Business_lens": "Logistics (Transportation) and Customer Service and Order Fulfillment",
        "KPI_Name": "OTR (On-Time to RAD)",
        "Business_definition": "The On-time delivery performance refers to the ratio of customer order lines shipped on or before the requested delivery date / customer promised date versus the total number of order lines.",
        "Business_Process": "Report Performance",
        "Report_Linked": "2023 LCC feeder template",
        "If_Yes_Formula": "(∑Orders Supplied on Time / ∑Orders received) *100",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "DC/Plant",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Item",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 24,
        "Function_Business_lens": "Logistics (Warehousing)",
        "KPI_Name": "Average Order Weight",
        "Business_definition": "average weight of an order / shipment",
        "Business_Process": "Logistics Cost Management",
        "Report_Linked": "Bracket Scorecard",
        "If_Yes_Formula": "∑Weight of all the orders/∑No. of shipments orders)",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "MT",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "N/A",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "Customer level/Sold to",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "John",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 25,
        "Function_Business_lens": "Logistics (Warehousing)",
        "KPI_Name": "Handling Cost",
        "Business_definition": "Handling costs refer to the types of costs associated with preparing and transporting inventory. Mostly, the cost cover expenses related to order fulfilment such as shipment and packaging cost.",
        "Business_Process": "Warehouse Cost Management",
        "Report_Linked": "S&H graphs",
        "If_Yes_Formula": "∑Total Variable cost/Avg stocks per day",
        "Aggregation_Rules": "Cost & Item",
        "Unit_of_Aggregation": "USD & Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "DC level",
        "Lowest_Desired_Granularity_Time_Period": "Periodically",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 26,
        "Function_Business_lens": "Logistics (Warehousing)",
        "KPI_Name": "Pallet Capacity",
        "Business_definition": "Maximum no of items that a pallet can accomodate",
        "Business_Process": "Inventory Health Management",
        "Report_Linked": "Inventory by DC Report",
        "If_Yes_Formula": "Total Space available/1 Pallet space",
        "Aggregation_Rules": "Space",
        "Unit_of_Aggregation": "Sq Ft",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "DC",
        "Lowest_Desired_Granularity_Time_Period": "Periodically",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 27,
        "Function_Business_lens": "Logistics (Warehousing)",
        "KPI_Name": "Pallet Projections",
        "Business_definition": "Number of pallets being projected to be manufactured or shipped",
        "Business_Process": "Inventory Health Management",
        "Report_Linked": "Warehouse Inventory Projections 12.18.23 part 2",
        "If_Yes_Formula": "∑Projected Inventory in Pallets",
        "Aggregation_Rules": "Cases",
        "Unit_of_Aggregation": "Pallets",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "DC",
        "Lowest_Desired_Granularity_Time_Period": "Periodically",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "Mentioned by Syed",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 28,
        "Function_Business_lens": "Logistics (Warehousing)",
        "KPI_Name": "Storage Cost",
        "Business_definition": "Storage costs are the amount of money incurred as a result of storing inventory. The costs could be direct or indirect money spent on the storage of goods.",
        "Business_Process": "Warehouse Cost Management",
        "Report_Linked": "S&H graphs",
        "If_Yes_Formula": "∑Warehouse Fixed cost",
        "Aggregation_Rules": "Cost",
        "Unit_of_Aggregation": "USD",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "DC level",
        "Lowest_Desired_Granularity_Time_Period": "Periodically",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 29,
        "Function_Business_lens": "Logistics (Warehousing)",
        "KPI_Name": "Warehouse Throughput (pallets, cases)",
        "Business_definition": "Number of units processed per time interval. Throughput typically quantifies the outbound operations within a warehouse.",
        "Business_Process": "Manage Logistics Costs",
        "Report_Linked": "S&H graphs",
        "If_Yes_Formula": "Total number of inbound/outboud shipment units processed divided by total man-hours",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Cases, Pallets",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Periodically",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 30,
        "Function_Business_lens": "Logistics (Warehousing)",
        "KPI_Name": "Warehouse Utilization - Actuals",
        "Business_definition": "Occupancy of warehouse pallets against total capacity",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Warehouse Inventory Projections 12.18.23 part 2",
        "If_Yes_Formula": "∑Warehouse Space Used /∑Warehouse Space Available) x 100",
        "Aggregation_Rules": "Area",
        "Unit_of_Aggregation": "Cases converted into Sqft",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Periodically",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 31,
        "Function_Business_lens": "Logistics (Warehousing)",
        "KPI_Name": "Warehouse Utilization - Projected",
        "Business_definition": "Forecasted occupancy of warehouse pallets against total capacity",
        "Business_Process": "Calculates projections",
        "Report_Linked": "Warehouse Inventory Projections 12.18.23 part 2",
        "If_Yes_Formula": "∑Warehouse Space Used /∑Warehouse Space Available) x 100",
        "Aggregation_Rules": "Area",
        "Unit_of_Aggregation": "Cases converted into Sqft",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Periodically",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 32,
        "Function_Business_lens": "Logistics (Warehousing) and Logistics (Transportation)",
        "KPI_Name": "LCC - Logistics Conversion Cost",
        "Business_definition": "Overall cost of logistics including Freight to Warehouse & Freight to Customer",
        "Business_Process": "Manage Logistics Costs",
        "Report_Linked": "S&OP SCMR_PROD",
        "If_Yes_Formula": "∑Logistics cost - ∑FTW + ∑FTC",
        "Aggregation_Rules": "Invoice",
        "Unit_of_Aggregation": "Cost",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "DC/Plant",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Item",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 33,
        "Function_Business_lens": "Logistics (Warehousing) and Logistics (Transportation)",
        "KPI_Name": "Logsitics Cost per Tonne",
        "Business_definition": "Shipping cost for every tonne of FG moved",
        "Business_Process": "Report Performance",
        "Report_Linked": "2023 LCC feeder template",
        "If_Yes_Formula": "∑Transportation Costs / ∑Tonnes shipped",
        "Aggregation_Rules": "Invoice",
        "Unit_of_Aggregation": "Cost & Metric Tonnes",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "DC/Plant",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Item",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "Trevor",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 34,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Cuts projection (0-12 weeks)",
        "Business_definition": "Measured in both cases as well as a % of demand",
        "Business_Process": "S&OE",
        "Report_Linked": "SPM V2 PROD (Use This)",
        "If_Yes_Formula": "∑Projected Orders - ∑Projected allocation",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 35,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Customer Service Level (CSL) Projected",
        "Business_definition": "Forecast of CSL for next N periods",
        "Business_Process": "Measure Supply At Risk",
        "Report_Linked": "Projected Cut Dashboard",
        "If_Yes_Formula": "∑Shipped Cases / ∑Ordered Cases",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases (MD)",
        "Synonyms": "Case Fill rate %",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 36,
        "Function_Business_lens": "Supply Planning, Demand Planning",
        "KPI_Name": "SFA - Sales Forecast Accuracy",
        "Business_definition": "Determines accuracy of demand forecast (lag 1,3) against actual sales\nWhat was sales ability to forecast the SALES we fulfilled vs FORECAST. This is currently review based on Forecast from LAG1 and LAG3 – Sales is measured on this metric",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Global Food Demand Consensus (PROD)",
        "If_Yes_Formula": "1 – [Absolute value of (∑Actual sales – ∑Forecast sales) / ∑Actual sale] for same time period",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases (Claire)",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant",
        "Lowest_Desired_Granularity_Time_Period": "Period",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "National",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 37,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "OFA - Order Forecast Accuracy",
        "Business_definition": "Means of measuring how well a demand forecast has predicted actual outcomes or values of sales",
        "Business_Process": "Measure past performance",
        "Report_Linked": "SFA and BIAS Dashboard 2.0",
        "If_Yes_Formula": "1 – [Absolute value of (∑Actual orders  – ∑Forecast orders) / ∑Actual orders] for same time period",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases (Claire)",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant",
        "Lowest_Desired_Granularity_Time_Period": "Period",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "National",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 38,
        "Function_Business_lens": "Supply Planning, Demand Planning",
        "KPI_Name": "Projected Production",
        "Business_definition": "Expected units of finished goods",
        "Business_Process": "Product Supply Plan",
        "Report_Linked": "Forescast vs capacity RTH, Production Volumes Input for SCMR",
        "If_Yes_Formula": "∑Production-Proj",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "Production volume projection",
        "Lowest_Desired_Granularity_Geography": "Production Line",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "National",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 39,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "DFC (Days forward coverage) cases",
        "Business_definition": "Amount of inventory (cases) available in terms of days",
        "Business_Process": "Inventory Health",
        "Report_Linked": "Warehouse Inventory Projections 12.18.23 part 2",
        "If_Yes_Formula": "∑Inventory  / average proj daily sales (as per 7 Days)",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV, COGS",
        "Synonyms": "DOI, DOS, DOH",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 40,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "ATS (Adherence to schedule)",
        "Business_definition": "Production schedule adherence",
        "Business_Process": "Factory Performance",
        "Report_Linked": "ATS New Format",
        "If_Yes_Formula": "(∑Planned - ∑actual)/ ∑planned",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 41,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "ATP (Adherence to plan)",
        "Business_definition": "how much is produced against planning provided",
        "Business_Process": "Factory Performance",
        "Report_Linked": "Global RTH ATP - 2023",
        "If_Yes_Formula": "(∑Planned - ∑actual)/ ∑planned",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 42,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Projected Demand",
        "Business_definition": "predict the future demand for a product or service",
        "Business_Process": "Product Supply Plan",
        "Report_Linked": "RTH stock level pivot, Demand comparison Food NA P1W3 (updated)",
        "If_Yes_Formula": "∑Demand-Proj",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 43,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Through put",
        "Business_definition": "Rate of production in unit time",
        "Business_Process": "Supply Planning",
        "Report_Linked": "TBD",
        "If_Yes_Formula": "∑FG Units produced/Time in days or hours",
        "Aggregation_Rules": "Items",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Production Line",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "National",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 44,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Production line utilization",
        "Business_definition": "% capacity of maximum at which production lines are operating",
        "Business_Process": "Product Supply Plan",
        "Report_Linked": "RTH Capacity feeder, Simulation tool NA 2024P1W3, Forecast vs capacity RTH",
        "If_Yes_Formula": "(∑Planned production in tonnage/Total line capacity in tonnage)*100",
        "Aggregation_Rules": "Items",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Production Line",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "National",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 45,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Cutroll",
        "Business_definition": "cutroll is the projected cuts plus 50% (estimated reorders ) of the cut total",
        "Business_Process": "Adjust Forecast",
        "Report_Linked": "24 P1 W3 D2 Projected Cuts Report RL CMVM",
        "If_Yes_Formula": "Projected cuts + 50% cuts of reorder",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Production Line",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "Item/SKU",
        "Lowest_Desired_Granularity_Customer": "National",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 46,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Supply chain loss *",
        "Business_definition": "losses incurred due to donate , destroy",
        "Business_Process": "Inventory Health",
        "Report_Linked": "TBD",
        "If_Yes_Formula": "No",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "",
        "Lowest_Desired_Granularity_Time_Period": "",
        "Lowest_Desired_Granularity_Product_Level": "",
        "Lowest_Desired_Granularity_Customer": "",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 47,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "Customer Service Level (CSL) Actuals",
        "Business_definition": "Shipped cases as a percentage of ordered cases",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Shipped Cases / ∑Ordered Cases",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases (MD)",
        "Synonyms": "Case Fill rate %",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 48,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "Items on Allocation",
        "Business_definition": "Upper limit of the no. of cases/pallets that can be ordered by a customer (in case of products which need prioritising within customers)",
        "Business_Process": "S&OE",
        "Report_Linked": "Daily Allocation & Quota Report",
        "If_Yes_Formula": "N/A",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 49,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "Fines",
        "Business_definition": "penalty imposed as a punishment for non compliance of material  delivery",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Fines & Fees Report",
        "If_Yes_Formula": "No",
        "Aggregation_Rules": "NA",
        "Unit_of_Aggregation": "USD",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 50,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "Fees",
        "Business_definition": "are charges levied for specific services or privileges",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Fines & Fees Report",
        "If_Yes_Formula": "No",
        "Aggregation_Rules": "NA",
        "Unit_of_Aggregation": "USD",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 51,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "Overage",
        "Business_definition": "refer to situations where the quantity or weight of goods received exceeds the expected or ordered amount",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Fines & Fees Report",
        "If_Yes_Formula": "No",
        "Aggregation_Rules": "NA",
        "Unit_of_Aggregation": "USD",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "Material",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 52,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "Shortage",
        "Business_definition": "occurs when the actual quantity of goods received falls short of what was expected or contracted for.",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Fines & Fees Report",
        "If_Yes_Formula": "No",
        "Aggregation_Rules": "NA",
        "Unit_of_Aggregation": "USD",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "Material",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 53,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "Amount accrued",
        "Business_definition": "total expenses related to freight that have been incurred by a company but not yet paid",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Paid & Accrued Tracking Report",
        "If_Yes_Formula": "No",
        "Aggregation_Rules": "NA",
        "Unit_of_Aggregation": "USD",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Del location",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 54,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "Amount paid",
        "Business_definition": "total cost incurred by a company for transporting goods from one location to another",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Paid & Accrued Tracking Report",
        "If_Yes_Formula": "No",
        "Aggregation_Rules": "NA",
        "Unit_of_Aggregation": "USD",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Del location",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 55,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "OT - Overall",
        "Business_definition": "refers to the overall deliveries that are made on time across all types of shipments, including both Full Truckload (FTL) and Less Than Truckload (LTL) shipments",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Transportation KPIs",
        "If_Yes_Formula": "Number of On-Time Deliveries / Total Number of Deliveries x 100",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Region",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 56,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "OT - LTL",
        "Business_definition": "less than truck load shipments that are delivered within the specified timeframe .",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Transportation KPIs",
        "If_Yes_Formula": "Number of On-Time LTL Deliveries / Total Number of LTL Deliveries x 100",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Region",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 57,
        "Function_Business_lens": "CS&L",
        "KPI_Name": "OT - FTL",
        "Business_definition": "Full Truckload shipments that are delivered within the specified timeframe .",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Transportation KPIs",
        "If_Yes_Formula": "Number of On-Time FTL Deliveries / Total Number of FTL Deliveries x 100",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Region",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 58,
        "Function_Business_lens": "CS&L, Supply Planning",
        "KPI_Name": "DOS - Days of Supply",
        "Business_definition": "How long inventory on hand will last. This measure projects the amount of inventory (stock) expressed in days of sales",
        "Business_Process": "S&OE",
        "Report_Linked": "Inventory by DC Report, Warehouse Inventory Projections 12.18.23 part 2",
        "If_Yes_Formula": "∑Inventory  / average proj daily sales (as per 7 Days)",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV, COGS",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Periodically",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "Inv",
        "field25": "Avg Sales"
    },
    {
        "S. No.": 59,
        "Function_Business_lens": "CS&L, Supply Planning",
        "KPI_Name": "Inventory - Cases (Base UOM)",
        "Business_definition": "Inventory refers to the finished goods stored inside a warehouse prior to production, shipping or selling.",
        "Business_Process": "Inventory Health Management",
        "Report_Linked": "Inventory by DC Report",
        "If_Yes_Formula": "∑Inventory",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 60,
        "Function_Business_lens": "CS&L, Supply Planning",
        "KPI_Name": "Inventory - $",
        "Business_definition": "Inventory refers to the finished goods stored inside a warehouse prior to production, shipping or selling.",
        "Business_Process": "Inventory Health Management",
        "Report_Linked": "Inventory Quality, Inventory by DC",
        "If_Yes_Formula": "∑Inventory",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "GSV & Cogs",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 61,
        "Function_Business_lens": "CS&L, Supply Planning",
        "KPI_Name": "Inventory Projections  (cases, kg, $, Pallets)",
        "Business_definition": "Future inventory value ($, GSV, cases) – by Sku/DC/Warehouse/Plant for certain no. of periods",
        "Business_Process": "Inventory Health Management",
        "Report_Linked": "Warehouse Inventory Projections 12.18.23 part 2",
        "If_Yes_Formula": "∑Inventory-Proj",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, Kgs, GSV, Pallets",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 62,
        "Function_Business_lens": "CS&L, Supply Planning",
        "KPI_Name": "SLOMO Inventory",
        "Business_definition": "Slow moving inventory is defined as stock keeping units (SKUs) that have not shipped in a certain amount of time, such as 90 or 180 days, and merchandise that has a low turn rate relative to the quantity on hand.",
        "Business_Process": "Inventory Health Management",
        "Report_Linked": "FOOD SLOMO BI",
        "If_Yes_Formula": "SLOMO Inventory = destroy  (<1 month)+ donate (1-3months) + liquidate (3-6 months) + fastrack (> 6 months)",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 63,
        "Function_Business_lens": "CS&L, Supply Planning",
        "KPI_Name": "Aging inventory",
        "Business_definition": "Aging inventory is a term for goods that haven’t sold quickly",
        "Business_Process": "Inventory Health Management",
        "Report_Linked": "Inventory Quality Dashboard",
        "If_Yes_Formula": "$ value or units of inventory falling in different shelf life buckets",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "COGS or Cases",
        "Synonyms": "SLOMO",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 64,
        "Function_Business_lens": "CS&L, Supply Planning",
        "KPI_Name": "Excess Inventory",
        "Business_definition": "Excess inventory refers to the stock that reached the end of its product life cycle and could not be sold. Considering that the inventory is excess also assumes that you do not expect to sell it in the foreseeable future.",
        "Business_Process": "Inventory Health Management",
        "Report_Linked": "Inventory Quality Dashboard",
        "If_Yes_Formula": "Excess Inventory = If Shelf Life Remaning is > 90 and <=252",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "COGS or Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 65,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Stockouts OOS / Out of Stock risk",
        "Business_definition": "The exposure to loss that arises from running out of one or more items of inventory.",
        "Business_Process": "Manage Inventory Risk",
        "Report_Linked": "Ben's Original Risks - NA, RTH Stock level pivot",
        "If_Yes_Formula": "∑Projected Inventory - ∑Demand (where demand > projected inventory) for specific time interval (12 weeks, 26 weeks, 52 weeks)",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "SKU",
        "Lowest_Desired_Granularity_Customer": "National",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 66,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Inventory health",
        "Business_definition": "Provides percentage detail of inventory that is in SLOMO (not fit for use)",
        "Business_Process": "Supply Planning",
        "Report_Linked": "Inventory Quality Dashboard",
        "If_Yes_Formula": "SLOMO Inventory / Total inventory",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "National",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 67,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Projected OOS date",
        "Business_definition": "The anticipated date when inventory levels will be finished based on current demand and supply parameters",
        "Business_Process": "Inventory",
        "Report_Linked": "FOOD_2_22_2024",
        "If_Yes_Formula": "Current Date + (Available Inventory / Average Daily Sales)",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV, COGS",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "",
        "Lowest_Desired_Granularity_Time_Period": "",
        "Lowest_Desired_Granularity_Product_Level": "",
        "Lowest_Desired_Granularity_Customer": "",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 68,
        "Function_Business_lens": "Supply Planning",
        "KPI_Name": "Average Daily Sales",
        "Business_definition": "Average Projected Sales",
        "Business_Process": "Inventory",
        "Report_Linked": "TBD",
        "If_Yes_Formula": "∑Projected Sales/Number of # days",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV, COGS",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "",
        "Lowest_Desired_Granularity_Time_Period": "",
        "Lowest_Desired_Granularity_Product_Level": "",
        "Lowest_Desired_Granularity_Customer": "",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 69,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "Bias",
        "Business_definition": "Indicates a tendency to either over-forecast (forecast is more than the actual), or under-forecast (forecast is less than the actual), leading to a forecasting error",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Global Food Demand Consensus (PROD)",
        "If_Yes_Formula": "(∑[Forecasted Sales] - ∑[Actual sales])/∑[Forecasted Sales]",
        "Aggregation_Rules": "demand unit",
        "Unit_of_Aggregation": "tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Production Line",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "Demand unit",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 70,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "$ Sales (Actuals)",
        "Business_definition": "Actual sales achieved by a company within a period",
        "Business_Process": "Revenue",
        "Report_Linked": "TBD",
        "If_Yes_Formula": "∑qty sold * selling price",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "",
        "Lowest_Desired_Granularity_Time_Period": "",
        "Lowest_Desired_Granularity_Product_Level": "",
        "Lowest_Desired_Granularity_Customer": "",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 71,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "$ Sales (Forecast)",
        "Business_definition": "Sales projections or estimates made by the company for a particular period",
        "Business_Process": "Projected Revenue",
        "Report_Linked": "TBD",
        "If_Yes_Formula": "No",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "",
        "Lowest_Desired_Granularity_Time_Period": "",
        "Lowest_Desired_Granularity_Product_Level": "",
        "Lowest_Desired_Granularity_Customer": "",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 72,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "SFA to order",
        "Business_definition": "Accuracy of sales forecasts in predicting the actual demand for a product , with respect to quantity of orders placed",
        "Business_Process": "Inventory",
        "Report_Linked": "SFA & Bias dashboard",
        "If_Yes_Formula": "1 – [Absolute value of (∑Actual Orders Placed – ∑Forecast sales) / ∑Actual Orders Placed] for same time period",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Production Line",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "Material",
        "Lowest_Desired_Granularity_Customer": "Demand group",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 73,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "SFA to shipment",
        "Business_definition": "Accuracy of sales forecasts in predicting the actual demand for a product , with respect to quantity of orders shipped",
        "Business_Process": "Inventory",
        "Report_Linked": "SFA & Bias dashboard",
        "If_Yes_Formula": "1 – [Absolute value of (∑Actual Orders Shipped – ∑Forecast sales) / ∑Actual Orders Shipped] for same time period",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Production Line",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "Material",
        "Lowest_Desired_Granularity_Customer": "Demand group",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 74,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "Bias to order",
        "Business_definition": "Any  deviation or tendency in sales forecasting that consistently overestimates or underestimates the quantity of orders needed for a product with respect to orders placed",
        "Business_Process": "Measure past performance",
        "Report_Linked": "SFA & Bias dashboard",
        "If_Yes_Formula": "(∑[Forecasted Sales] - ∑[Orders placed])/∑[Forecasted Sales]",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Production Line",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "Material",
        "Lowest_Desired_Granularity_Customer": "Demand group",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 75,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "Bias to shipment",
        "Business_definition": "Any  deviation or tendency in sales forecasting that consistently overestimates or underestimates the quantity of orders needed for a product with respect to orders shipped to customer",
        "Business_Process": "Measure past performance",
        "Report_Linked": "SFA & Bias dashboard",
        "If_Yes_Formula": "(∑[Forecasted Sales] - ∑[Orders shipped])/∑[Forecasted Sales]",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Production Line",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "Material",
        "Lowest_Desired_Granularity_Customer": "Demand group",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 76,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "GSV Forecast",
        "Business_definition": "Projected demand for a product in terms of Gross Sales Value",
        "Business_Process": "Projected Revenue",
        "Report_Linked": "TBD",
        "If_Yes_Formula": "∑Forecasted demand qty * selling price",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "",
        "Lowest_Desired_Granularity_Time_Period": "",
        "Lowest_Desired_Granularity_Product_Level": "",
        "Lowest_Desired_Granularity_Customer": "",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 77,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "GSV Replan",
        "Business_definition": "Revised demand forecast in terms of Gross Sales Value based on changes in market conditions, customer demand or other relevant factors",
        "Business_Process": "Projected Revenue",
        "Report_Linked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "If_Yes_Formula": "∑updated forecasted qty   * selling price",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 78,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "GSV Unconstrained",
        "Business_definition": "Estimate of the total potential revenue that could be generated under ideal conditions, assuming no constraints on production or distribution.",
        "Business_Process": "Projected Revenue (unconstrained)",
        "Report_Linked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "If_Yes_Formula": "∑demand * selling price",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 79,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "GSV OP (operating plan)",
        "Business_definition": "Sales plan (in terms of demand projections) that outlines the expected sales revenue generated from the core operations of a business over a specific period",
        "Business_Process": "Projected Revenue",
        "Report_Linked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "If_Yes_Formula": "GSV inital * adjustment factor",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 80,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "GSV Financial (Rev Call)",
        "Business_definition": "Projected Sales targets (in terms of GSV) defined for a period based on latest demand estimates",
        "Business_Process": "Projected Revenue",
        "Report_Linked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "If_Yes_Formula": "∑qty forecasted * selling price",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 81,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "NSV Financial vs PY NSV",
        "Business_definition": "refers to the comparison between the NSV in the current financial period and the NSV in the previous year (PY).",
        "Business_Process": "Measure past performance",
        "Report_Linked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "If_Yes_Formula": "",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Period level",
        "Lowest_Desired_Granularity_Product_Level": "N/A",
        "Lowest_Desired_Granularity_Customer": "N/A",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 82,
        "Function_Business_lens": "Demand Planning",
        "KPI_Name": "Risk adjusted GSV",
        "Business_definition": "Forecasted Sales (in terms of GSV) adjusted to account for various risks and uncertainties that may impact the actual realization of sales revenue",
        "Business_Process": "Projected Revenue (Constrained)",
        "Report_Linked": "2024 (Cycle 1) Total Food GSV roll-up Final",
        "If_Yes_Formula": "base GSV * (1 - Risk factor)",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Production location",
        "Lowest_Desired_Granularity_Time_Period": "Quarter",
        "Lowest_Desired_Granularity_Product_Level": "Category",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "",
        "field22": "",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 83,
        "Function_Business_lens": "Other than CS&L",
        "KPI_Name": "Order cases - projection",
        "Business_definition": "# of cases ordered (projected) for a given material in a sales document",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Ordered Cases- Proj",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 84,
        "Function_Business_lens": "Other than CS&L",
        "KPI_Name": "Shipment Cases - projection",
        "Business_definition": "# of cases shipped (projected) for a given material in an invoice",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Shipped Cases- Proj",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant/DC level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 85,
        "Function_Business_lens": "Other than CS&L",
        "KPI_Name": "Items on Quota",
        "Business_definition": "Upper limit of the no. of cases/pallets that can be ordered by a customer",
        "Business_Process": "S&OE",
        "Report_Linked": "Daily Allocation & Quota Report",
        "If_Yes_Formula": "∑Forecast errors over multiple periods / ∑observed periods",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 86,
        "Function_Business_lens": "Other than CS&L",
        "KPI_Name": "Order vs Shipment (GSV)",
        "Business_definition": "DIfference of shipped versus ordered cases  ($ value)",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Shipped-∑Ordered cases",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "GSV (USD)",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "Formula",
        "field24": "∑(Invetory)items",
        "field25": "∑(sales)item in a period/28 Days"
    },
    {
        "S. No.": 87,
        "Function_Business_lens": "Other than CS&L",
        "KPI_Name": "Order vs Shipment (Cases)",
        "Business_definition": "DIfference of shipped versus ordered cases - numerical",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Shipped-∑Ordered cases",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 88,
        "Function_Business_lens": "Other than CS&L",
        "KPI_Name": "Inventory - Tonnage",
        "Business_definition": "Inventory refers to the finished goods stored inside a warehouse prior to production, shipping or selling.",
        "Business_Process": "Inventory Health Management",
        "Report_Linked": "Inventory Quality, Inventory by DC",
        "If_Yes_Formula": "∑Inventory",
        "Aggregation_Rules": "",
        "Unit_of_Aggregation": "Tonnage",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Weekly",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 89,
        "Function_Business_lens": "Other than CS&L",
        "KPI_Name": "Shipments Pacing",
        "Business_definition": "It is the the speed with which orders are shipped / day, helps in determing, whether all the orders will be shipped or not",
        "Business_Process": "S&OE",
        "Report_Linked": "Order Bank",
        "If_Yes_Formula": "Actual shipment x Days gone (in a period)/Official Forecast*28",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "Cases, GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "N/A",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "No",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    },
    {
        "S. No.": 90,
        "Function_Business_lens": "Other than CS&L",
        "KPI_Name": "Cut GSV",
        "Business_definition": "Gross sales value of cases not shipped from a given order",
        "Business_Process": "Measure past performance",
        "Report_Linked": "Customer Case Fill Dashboard 2.0",
        "If_Yes_Formula": "∑Ordered GSV -∑ Shipped GSV",
        "Aggregation_Rules": "Item",
        "Unit_of_Aggregation": "GSV",
        "Synonyms": "",
        "Lowest_Desired_Granularity_Geography": "Plant level",
        "Lowest_Desired_Granularity_Time_Period": "Daily",
        "Lowest_Desired_Granularity_Product_Level": "Material level",
        "Lowest_Desired_Granularity_Customer": "Customer level",
        "Data Quality Assessment – Good, OK, Bad": "",
        "Gold_KPIs": "Yes",
        "Used by food": "Yes",
        "Desired Refresh Frequency": "",
        "Priority \nHigh/Medium/Low": "",
        "Data Owner": "",
        "Comment": "N/A",
        "field22": "#REF!",
        "field23": "",
        "field24": "",
        "field25": ""
    }
   
]