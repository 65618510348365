import React, { useEffect, useState } from "react";
// import Select from "react-select";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import classes from "./UserForm.module.css";

const UserForm = ({
  addUser,
  closeHandler,
  selectedUser,
  editFlag,
  clearData,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [userGroups, setUserGroups] = useState([]);
  const [errors, setErrors] = useState({});

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate form fields
    if (!firstName || !lastName || !email || !password || !role) {
      setErrors({
        firstName: !firstName ? "First Name is required" : "",
        lastName: !lastName ? "Last Name is required" : "",
        email: !email ? "Email is required" : "",
        password: !password ? "Password is required" : "",
        role: !role ? "Role is required" : "",
        // userGroups: userGroups.length === 0 ? "User group(s) is required" : "",
      });
      return;
    }

    // Validate email format
    if (!validateEmail(email)) {
      setErrors({ email: "Please enter a valid email address" });
      return;
    }
    // Validate password
    if (!validatePassword(password)) {
      setErrors({
        password:
          "Password must be 8-20 characters long and contain at least one special character, one uppercase letter, and one number",
      });
      return;
    }

    // Add user to table
    addUser({
      firstName,
      lastName,
      email,
      password,
      role,
      ID: selectedUser.ID,
    });

    // Clear form fields
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setRole("");
    // setUserGroups([]);
    setErrors({});
    closeHandler();
  };

  // Function to handle first name input change
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setErrors({ ...errors, firstName: "" });
  };

  // Function to handle last name input change
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setErrors({ ...errors, lastName: "" });
  };

  // Function to validate password
  const validatePassword = (password) => {
    const regex =
      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/;
    return regex.test(password);
  };

  // Function to handle email input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: "" });
  };

  // Function to handle role select change
  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setErrors({ ...errors, role: "" });
  };

  // Function to handle password input change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors({ ...errors, password: "" });
    if (!validatePassword(e.target.value)) {
      setErrors({
        password:
          "Password must be 8-20 characters long and contain at least one special character, one uppercase letter, and one number",
      });
    }
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // let selectedUserGroup = [];

  // if (!editFlag) {
  //   selectedUserGroup = selectedUser.UserGroup.map((user) => {
  //     return {
  //       value: user,
  //       label: user,
  //     };
  //   });
  // }

  // Function to handle user groups select change
  // const handleUserGroupsChange = (selectedOptions) => {
  //   setUserGroups(selectedOptions);
  //   setErrors({ ...errors, userGroups: "" });
  // };

  // Function to validate email format using regex
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  useEffect(() => {
    if (!editFlag) {
      setFirstName(selectedUser.firstName);
      setLastName(selectedUser.lastName);
      setEmail(selectedUser.Email);
      setRole(selectedUser.Role);
      // setUserGroups(selectedUserGroup);
      setPassword(selectedUser.password);
    }
  }, [editFlag]);

  useEffect(() => {
    if (clearData) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setRole("");
      // setUserGroups([]);
      setPassword("");
      setErrors({});
    }
  }, clearData);

  return (
    <form
      onSubmit={handleSubmit}
      className={classes["user-form-container"]}
      autocomplete="off"
    >
      <div className={classes["first-section"]}>
        <div className={`${classes["name-cont"]} ${classes["input-common"]}`}>
          <label>First Name</label>
          <input
            type="text"
            value={firstName}
            onChange={handleFirstNameChange}
            placeholder="Enter first name"
            className={classes["common-input-style"]}
            style={{ borderColor: errors.firstName ? "red" : "#0000a0" }}
          />
          {errors.firstName && (
            <span className={classes.error}>{errors.firstName}</span>
          )}
        </div>
        <div className={`${classes["name-cont"]} ${classes["input-common"]}`}>
          <label>Last Name</label>
          <input
            type="text"
            value={lastName}
            onChange={handleLastNameChange}
            placeholder="Enter last name"
            className={classes["common-input-style"]}
            style={{ borderColor: errors.lastName ? "red" : "#0000a0" }}
          />
          {errors.lastName && (
            <span className={classes.error}>{errors.lastName}</span>
          )}
        </div>
      </div>
      <div className={classes["first-section"]}>
        <div className={`${classes["email-cont"]} ${classes["input-common"]}`}>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter email"
            className={classes["common-input-style"]}
            autocomplete="off"
            style={{ borderColor: errors.email ? "red" : "#0000a0" }}
          />
          {errors.email && (
            <span className={classes.error}>{errors.email}</span>
          )}
        </div>
        <div className={`${classes["role-cont"]} ${classes["input-common"]}`}>
          <label>Role</label>
          <select
            value={role}
            onChange={handleRoleChange}
            className={classes["common-input-style"]}
            style={{ borderColor: errors.role ? "red" : "#0000a0" }}
          >
            <option value="" style={{ color: "#222" }} selected disabled hidden>
              Select Role
            </option>
            <option value="Admin">Admin</option>
            <option value="Member">Member</option>
          </select>
          {errors.role && <span className={classes.error}>{errors.role}</span>}
        </div>
      </div>
      {/* <div className={classes["password-cont"]}>
        <label>Password</label>
        
        <div className={classes["password-input-wrapper"]}>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter Password"
            className={classes["password-input"]}
            autocomplete="new-password"
            style={{ borderColor: errors.password ? "red" : "#0000a0" }}
          />

          {password && (
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className={classes["show-password-button"]}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </button>
          )}
        </div>
        {errors.password && (
          <span className={classes.error}>{errors.password}</span>
        )}
      </div> */}
      <div className={classes["form-btn-cont"]}>
        <button
          
          className={`${classes["secondary-btn"]} ${classes.btn}`}
          type="button"
          onClick={() => closeHandler()}
        >
          Cancel
        </button>
        <button
          style={{background:"#3d3d3d"}}
          type="submit"
          className={`${classes["primary-btn"]} ${classes.btn}`}
        >
          {editFlag || clearData ? "Add User" : "Save"}
        </button>
      </div>
    </form>
  );
};

export default UserForm;
