import React, { useState, useRef } from "react";
import Modal from '@mui/material/Modal';
import MainLayout from "../nav/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import ReactPaginate from "react-paginate";
import { Button } from "react-bootstrap";
import $ from "jquery";
import JsonData from '../../data-directory.json'
import {dataDictionary} from '../data/data-dictionary';
import ReportGenerate from "../reuseable/ReportGenerate";
import EditDataDirectory from "./EditDataDirectory";
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DataDirectory = () => {
  
  const [error, setError] = useState(false);
  const [iserror, setisError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagelimit, setPageLimit] = useState(10);
  const [filterFunctionBusiness, setfilterFunctionBusiness] = useState("");
  const [filterByCalculated, setfilterByCalculated] = useState("");
  const [query, setQuery] = useState("");
  const[editData, setEditData] = useState(null);
  const[openModal, setOpenModal] = useState(false);

  const componentRef_1 = useRef(null);

  const items = dataDictionary;
  const sortedAsc = (items === null ? [] : items.slice(0).sort((a, b) => {
    // Check if 'S. No' property exists in a and b
    if (a && b && a['S. No'] && b['S. No']) {
        return b['S. No'].localeCompare(a['S. No']);
    } else {
        // Handle case where 'S. No' property is missing
        return 0; // Or return a value suitable for your sorting logic
    }
  }));
    

   const data = sortedAsc === null ? " " : Object.values(sortedAsc);
   
    const search_parameters = ["KPI_Name",'Function_Business_lens','Gold_KPIs','Business_definition','Business_Process','If_Yes_Formula','Report_Linked','Aggregation_Rules','Unit_of_Aggregation','Synonyms','Lowest_Desired_Granularity_Geography','Lowest_Desired_Granularity_Time_Period','Lowest_Desired_Granularity_Product_Level','Lowest_Desired_Granularity_Customer'];
    const Filter_Function_Business_lens1 = [...new Set(data.map((item) => item.Function_Business_lens))];
    const Filter_Calculated_Yes_No1 = [...new Set(data.map((item) => item.Gold_KPIs))];

    const Filter_Calculated_Yes_No = Filter_Calculated_Yes_No1.filter(item => item !== '');
    const Filter_Function_Business_lens = Filter_Function_Business_lens1.filter(item => item !== '');
    


  

    let filteredData = [];

    if(items !== null){
        filteredData = sortedAsc.filter((obj) => {
            return obj;
          });
    }

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
      };
      
    function search(sortedAsc) {
        return sortedAsc.filter(
          (item) =>
            item.Gold_KPIs?.includes(filterByCalculated) &&
            item.Function_Business_lens?.includes(filterFunctionBusiness) &&

            search_parameters.some(
              (parameter) =>
                item[parameter].toString().toLowerCase().includes(query.toLowerCase()) 
            )
           
        );
    }

      let NUM_OF_RECORDS = search(filteredData).length;
      if (NUM_OF_RECORDS <= currentPagelimit) {
        $(".pagination").hide(true);
      } else {
        $(".pagination").show(true);
      }

      const currentData = search(filteredData).slice(
        (currentPage - 1) * currentPagelimit,
        (currentPage - 1) * currentPagelimit + currentPagelimit
      );
    
      const pageCount = Math.ceil(search(filteredData).length / currentPagelimit);

      const handelepageclick = (data) => {
        console.log(data.selected);
        setCurrentPage(data.selected + 1);
      };

     console.log("currentData")
      console.log(JsonData)
      const resetAll = () =>{
        handlePageChange({ selected: 1 });
        setfilterFunctionBusiness("");
        setQuery("");
        setfilterByCalculated("");
      }

    const transformData = (excelData) => {    
      const newArray = excelData.map(obj => {        
          const { KPI_Name, Business_definition, Function_Business_lens, Business_Process, Gold_KPIs, If_Yes_Formula, Synonyms } = obj;
          return { "KPI Name":KPI_Name, "Business Definition": Business_definition, "Function Business Lens": Function_Business_lens, 
          "Business Process":Business_Process , "Gold KPIs":Gold_KPIs, "If Yes, Formula": If_Yes_Formula, "Synonyms": Synonyms};
      });
      return newArray;
    };


  const showBusinessLens = (ele) => {
    const newEle = ele.split(',');
    console.log(newEle);
    return (
      newEle.map((item) => (
        <span
        style={{
          display: "grid",
          justifyContent: "center",
          cursor:"pointer",
          marginBottom: '8px',
        }}
      >
        {item === "CS&L" ? (
          <div className="item_CSL itemsFlex">
            <span title={item}>{item}</span>
          </div>
        ) : item === "Supply Planning" ? (
          <div className="supplyPlaning itemsFlex">
            <span title={item}>{item}</span>
          </div>
        ) : (item === "Supply Planning, CS&L" || item === "Supply Planning, CS&L") ? (
          <div className="SupplyPlanningCSL itemsFlex">
            <span title={item}>{item}</span>
          </div>
        ) : item === "Demand Planning" ? (
          <div className="DemandPlanning itemsFlex">
            <span title={item}>{item}</span>
          </div>
        ): item === "Demand Planning, Supply Planning" ? (
          <div className="DemandPlanningSupplyPlanning itemsFlex">
            <span title={item}>{item}</span>
          </div>
        )
        : item === "-" ? (
          <div>
            <span title={`No Data Available because of state is ${item}`}>{item}</span>
          </div>
        ) : (
          <div className=" itemsFlex">
            <span title={item}>
              {item === "" ||
              item === "NA"
                ? "N/A"
                : item}
            </span>
          </div>
        )}
        </span>
      ))
    )
  }


  return (
    <div id="homePage">
      <Box className="mainLayout" sx={{ display: "flex"}}>
        <MainLayout />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div style={{padding:"0px", overflowY: "auto"}} id="mainHome">

          {/* {isloading && (
          <div
            style={{
              height: "100%",
              display: "grid",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div className="PlaseWait">
              <div className="spinner_class">
              </div>
              <div>Please wait sometime</div>
            </div>
          </div>
        )} */}

        { filteredData.length !== 0 && !error && (
          <>
            <div className="wrapper">
              <div
                style={{ paddingBottom: "1rem", gridTemplateColumns: "100%" }}
                className="searchBarSections"
              >
                <div
                  style={{
                    paddingTop: "2rem",
                    paddingBottom: "2rem",
                    display: "flex",
                    gridTemplateColumns: "100%",
                    justifyContent: "space-between",
                    alignItems: 'center'
                  }}
                  className="searchBarSections"
                >
                  <div style={{padding: "15px"}} >
                    <div className="heading1"  >
                      Data Dictionary
                    </div>
                    <div className='line1' />
                  </div>


                  <div>
                    <ReportGenerate
                      excelData={filteredData}
                      fileName={`Data_directory_${new Date().getTime()}`}
                      transformData={transformData}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "0rem",
                  flexWrap: "wrap",
                      gridGap: "10px",
                  padding: "10px"
                }}
              >
                <div className="search-wrapper">
                  <input
                    type="search"
                    name="search-form"
                    id="search-form"
                    value={query}
                    className="SearchBox_newworkflow"
                    placeholder="Search here"
                    autoComplete="off"
                    onChange={(e) => {
                      setQuery(e.target.value);
                      handlePageChange({ selected: 1 });
                    }}
                  />
                </div>

                <div style={{ display: "flex", gridGap: "20px" }}>
                
                  <div className="selectParent select">
                    <select
                      value={filterByCalculated}
                      onChange={(e) => {
                        setfilterByCalculated(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow"
                      aria-label="Filter Calculated"
                    >
                      <option value="">Select Gold KPIs</option>
                      {Filter_Calculated_Yes_No.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <div className="selectParent select">
                    <select
                      value={filterFunctionBusiness}
                      onChange={(e) => {
                        setfilterFunctionBusiness(e.target.value);
                        handlePageChange({ selected: 1 });
                      }}
                      className="custom-select custom-selectworkflow"
                      aria-label="Filter status"
                    >
                      <option value="">Select Business Function</option>
                      {Filter_Function_Business_lens.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <span className="focus"></span>
                  </div>

                  <Button
                    style={{
                      background: "#253878",
                      border: "none",
                      outline: "none",
                      cursor: 'pointer'
                    }}
                    onClick={resetAll}
                  >
                    Reset
                  </Button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    paddingBottom: "1rem",
                    paddingTop: "1rem",
                    display:"flex",
                        alignItems: "center",
                    padding: "10px"
                  }}
                >
                  Total number of Data{" "}
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#253978",
                      paddingLeft:"10px"
                    }}
                  >
                    {search(filteredData).length}
                  </span>
                 

                </div>

                <div
                  style={{
                    display: "flex",
                    gridGap: "10px",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <div>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handelepageclick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={currentPage - 1}
                    />
                  </div>
                </div>
              </div>

              <div className="table_class" >
                    <table
                  id="DataTable_dictionary"
                  ref={componentRef_1}
                  style={{width: "100%",}}
                      className="workflowTable"
                      
                    >
                      
                      <thead className="table_header" >
                         <tr>
                      <th
                        style={{
                          background: "#d0d8e2",
                          color: "#253978",
                              textAlign: "center",
                        }}
                      >
                        KPI Name
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                          
                          
                        }}
                      >
                        Business definition
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                        Function 
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                       Business Process
                      </th>
                        <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                        Reports Linked
                        </th>
                          <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",

                        }}
                      >
                        If Yes, Formula
                      </th>
                        
                          <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                        Aggregation Rules

                        </th>
                        <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                       Unit of Aggregation
                      </th>
                    

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                       Synonyms 
                        </th>
                         <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                       Lowest Desired Granularity (Geography) 
                        </th>
                         <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                      Lowest Desired Granularity (Time Period)
                        </th>
                         <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                       Lowest Desired Granularity (Product Level) 
                        </th>
                         <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                       Lowest Desired Granularity (Customer) 
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                      >
                       Gold KPIs 
                      </th>

                      <th
                        style={{
                          textAlign: "center",
                          background: "#d0d8e2",
                          color: "#253978",
                        }}
                        >
                          Edit
                      </th>
                    </tr>
                      </thead>

                      <tbody style={{
                    overflowX: "scroll"
                  }} >
                   

                    {search(filteredData).length !== 0 && (
                      <>
                        {currentData.map((item, index) => (
                          <tr key={index}>
                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "200px",
                            fontWeight: "bold"


                                }}
                              >
                                <span
                                  title= {item.KPI_Name} 
                                  className="kpi_name"
                                >
                                      {item.KPI_Name}                                 
                                </span>
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "200px",

                                }}
                              >
                                <span
                                  title= {item.Business_definition} 
                                  className="data_directory_items_cls"
                                >
                                      {item.Business_definition}                                 
                                </span>
                              </span>
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                                <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  textAlign:"left",
                                  width: "200px",
                                  cursor:"pointer"
                                }}
                              >
                                
                                  <div className="data_directory_items_critical data_directory__loc itemsFlex">
                                    <span title={item.Function_Business_lens}>
                                      {showBusinessLens(item.Function_Business_lens)}
                                    </span>
                                  </div>
                              
                              </span>


                              {/* {showBusinessLens(item.Function_Business_lens)} */}
                            </td>

                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  textAlign:"center",
                                  width: "100px",
                                  cursor:"pointer"
                                }}
                              >
                                
                                  <div className="data_directory_items_critical data_directory__loc itemsFlex">
                                    <span title={item.Business_Process}>
                                      {item.Business_Process === "" ||
                                      item.Business_Process === "N/A"
                                        ? "N/A"
                                        : item.Business_Process}
                                    </span>
                                  </div>
                              
                              </span>
                            </td>
                            <td style={{ fontWeight: "normal" }}>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "150px",

                                }}
                              >
                                <span
                                  title= {item.Report_Linked} 
                                  className="kpi_name"
                                >
                                      {item.Report_Linked}                                 
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "150px"
                                  
                                }}
                              >
                                <span title={item.If_Yes_Formula} className="data_directory_items_cls state_na_in_snow">
                                  {(item.If_Yes_Formula === 'NA') ? 'N/A' : item.If_Yes_Formula}
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor:"pointer"
                                }}
                              >
                                <span title={item.Aggregation_Rules} className="data_directory_items_cls state_na_in_snow">
                                  {item.Aggregation_Rules}
                                </span>
                              </span>
                            </td>

                             <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor:"pointer"
                                }}
                              >
                                <span title={item.Unit_of_Aggregation} className="data_directory_items_cls state_na_in_snow">
                                  {item.Unit_of_Aggregation}
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor:"pointer"
                                }}
                              >
                                <span title={item.Synonyms} className="data_directory_items_cls state_na_in_snow">
                                  {item.Synonyms}
                                </span>
                              </span>
                            </td>

                             <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "120px"
                                }}
                              >
                                <span title={item.Lowest_Desired_Granularity_Geography} className="data_directory_items_cls state_na_in_snow">
                                  {item.Lowest_Desired_Granularity_Geography}
                                </span>
                              </span>
                            </td>
                             <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "120px"
                                  
                                }}
                              >
                                <span title={item.Lowest_Desired_Granularity_Time_Period} className="data_directory_items_cls state_na_in_snow">
                                  {item.Lowest_Desired_Granularity_Time_Period}
                                </span>
                              </span>
                            </td>
                             <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "120px"
                                  
                                }}
                              >
                                <span title={item.Lowest_Desired_Granularity_Product_Level} className="data_directory_items_cls state_na_in_snow">
                                  {item.Lowest_Desired_Granularity_Product_Level}
                                </span>
                              </span>
                            </td>
                             <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "120px"
                                  
                                }}
                              >
                                <span title={item.Lowest_Desired_Granularity_Customer} className="data_directory_items_cls state_na_in_snow">
                                  {item.Lowest_Desired_Granularity_Customer}
                                </span>
                              </span>
                            </td>


                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "100px"
                                  
                                }}
                              >
                                <span className="data_directory_items_cls state_na_in_snow" 
                                style={{background: item.Gold_KPIs === 'Yes' ? 'green' : 'red', fontWeight: '600', padding: '0px 6px', borderRadius: '4px', color: 'white'}}>
                                  {item.Gold_KPIs !== 'N/A' && (
                                    <sapn title={item.Gold_KPIs}>
                                    {item.Gold_KPIs}
                                  </sapn>
                                  )}

                                  {(item.Gold_KPIs === 'N/A') && (
                                    <>{item.Gold_KPIs}</>                                  
                                  )}
                                </span>
                              </span>
                            </td>

                            <td>
                              <span
                                style={{
                                  display: "grid",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  width: "100px"
                                }}
                              >
                                <button type="button" class="btn btn-link" onClick={() => {setOpenModal(true)
                                  setEditData(item)}}>Edit</button>
                              </span>
                            </td>
                    
                          </tr>
                        ))}
                      </>
                    )}

                    {search(filteredData).length === 0 && (
                      <tr style={{ textAlign: "center" }}>
                        <td
                          style={{ textAlign: "center", color: "red" }}
                          rowSpan={4}
                          colSpan={9}
                        >
                          No Data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                </div>
              
            </div>
          </>
        )}

        {error && (
          <div className="PlaseWait">
            <div className="spinner_class">
              {`There is a problem fetching the post data ${iserror}`}
            </div>
            <div>Please try after sometime</div>
          </div>
        )}

        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
          >
            <div className="modal-box">
              <EditDataDirectory editData={editData}/>
            </div> 
        </Modal>
           
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default DataDirectory;
