import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import bgimage from "../assets/img/Aays-login-page-img-2.jpg";
import logo from "../assets/img/Aays.png";

import Home from '../pages/Home';
import "bootstrap/dist/css/bootstrap.min.css";


export default withOktaAuth(
  class SigninWidget extends Component {
    constructor(props) {
      super(props);
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
    }

    async login() {
      await this.props.oktaAuth.signInWithRedirect();
    }

    async logout() {
      await this.props.oktaAuth.signOut();
    }

    render() {
      let body = null;
      if (this.props.authState?.isAuthenticated) {
        body = (
          <div className="Buttons">
            <Home/>
          </div>
        );
      } else {
        body = (
          <div className="Buttons">
            <div className="loginPage">
              <div className="login_body_content">
                <div className="bg-white dark:bg-gray-900 bgColor">
                  <div className="login_body_grid">
                    <div style={{ backgroundImage: `url(${bgimage})`}} className="hidden bg-cover lg:block lg:w-2/3 login_bg_image loginBgimage">
                      <div className="login_info">
                        <div style={{display:"grid",justifyContent:"center"}}>

                       

                          <div style={{display:"grid",justifyContent:"center"}} className="text-4xl font-bold text-white">
                            <h1 style={{background:"#bb1ccc",padding:"10px",fontSize:"34px"}}>AI App Store For Finance</h1>
                          </div>

                          <p style={{width:"70%"}} className="left_grid_text">
                              Welcome to AI App Store For Finance. Access to this system is limited - please contact <a rel="noreferrer" target={"_blank"} style={{color:"black"}} href="mailto:anshuman.bhar@aaysanalytics.com"><mark>anshuman.bhar@aaysanalytics.com</mark></a> for details.
                          </p>
                          
                        </div>
                      </div>
                    </div>

                    <div className="right_grid_align bgColor">
                      <div className="flex-1">
                        <div className="text-center">
                          <span className="text-4xl font-bold text-center text-gray-700 dark:text-white MarsLogo">
                            <img width={130} src={logo} alt="img" />
                          </span>

                          <p className="left_grid_text left_margin">
                            Sign in to access your account
                          </p>
                        </div>

                        <div className="mt-8">
                          <div className="mt-6">
                            <button
                              className="btn_login"
                              onClick={this.login}>
                              Sign in
                            </button>
                          </div>
                        </div>

                      <div style={{width:"70%",paddingTop:"1rem",display:"block",margin:"auto"}}>
                        <span id="Login_herf" style={{color:"white",display:"flex",justifyContent:"space-between"}}>
                          <a  target={"_blank"} rel="noreferrer" style={{color:"white",textDecoration:"none"}} href="mailto:anshuman.bhar@aaysanalytics.com?subject=Need Access to the  Data world of Mars Food Application!"> Need Access?</a>

                          <a  target={"_blank"} rel="noreferrer" style={{color:"white",textDecoration:"none"}} href="mailto:anshuman.bhar@aaysanalytics.com?subject=Need Help On Sign in On  Data world of Mars Food Applications!"> Need Help?</a>
                          </span>
                      </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return <div className="App">{body}</div>;
    }
  }
);
